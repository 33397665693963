// CONTACT SECTION

// ------------------------------------------
// Desktop defaults

#contact {
    text-align: center;
    max-width: 900px;
}

.card-contact {
    width: 300px;
    transition: all 0.1s ease-out;

    svg {
        width: 100%;
        height: auto;

        path {
            fill: white;
        }

    }

}

.contact-card-container {
    max-width: 1000px;
}

.contact-card-container-anchor {
    margin: 2em 4em;
}

// ------------------------------------------
// Contacts mobile

@media screen and (max-width: 430px){

    #contacts h2 {
        font-size: 74px;
    }

    .contact-card-container-anchor {
        margin: 1em 4em;
    }

    .card-contact {
        width: 220px;
    }

}
