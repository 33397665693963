// HOME SECTION
@use '../utils/mixins';

// ------------------------------------------
// Desktop defaults

@keyframes fade {
    0% {opacity: 0%;}
    10% {opacity: 100%;}
    20% {opacity: 100%;}
    30% {opacity: 0%;}
    40% {opacity: 0%;}
    50% {opacity: 0%;}
    60% {opacity: 0%;}
    70% {opacity: 0%;}
    80% {opacity: 0%;}
    90% {opacity: 0%;}
    100% {opacity: 0%;}
}

#home {

    display: grid;
    grid-template-columns: 10% 50% 40%;
    grid-template-rows: auto auto;
    grid-template-areas:
    "left-margin left-content-hi right-content"
    "left-margin left-content-lo right-content";

    h1 {
        font-family: $font-primary;
        font-size: 110px;
        margin: auto 0 5vh 0;
        color: white;
        line-height: 0.8em;
        grid-area: left-content-hi;
    }

    p {
        font-family: $font-secondary;
        font-weight: 300;
        font-size: 52px;
        margin: 0 0 auto 0;
        color: white;
        line-height: 1.1em;
        grid-area: left-content-lo;
    }

}

#home-image {
    grid-area: right-content;
    display: flex;
    color: white;
    position: relative;
    justify-content: center;
    align-items: center;

    // Adjustment for Big Head
    top: -50px;

}

.front-page-icon {
    font-size: 20vw !important;
    position: absolute;
}

#desktop-windows {
    @include home-icon-animation(0s);
}

#keyboard {
    @include home-icon-animation(2s);
}

#design-services {
    @include home-icon-animation(4s);
}

// ------------------------------------------
// Home mobile
@media screen and (max-width: 430px){

    #home {
        display: grid;
        grid-template-rows: 30% 40% 30%;
        grid-template-columns: none;
        grid-template-areas: 
            "top-content"
            "mid-content"
            "bot-content";

        h1 {
            font-size: 74px;
            line-height: 0.9em;
            grid-area: top-content;
            margin: auto 0 2vh 7vw;
        }

        p {
            font-size: 19px;
            line-height: 1.3em;
            grid-area: bot-content;
            margin: 2vh 7vw auto 7vw;
        }

    }

    #home-text {
        grid-area: top-content;
        margin: 20% 13vw 0 10vw;
    }

    #home-image {
        grid-area: mid-content;
        top: -25px;

        svg {
            height: 250px;
        }

    }

    .front-page-icon {
        font-size: 50vw;
    }

}