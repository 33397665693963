// NAVBAR

// ------------------------------------------
// Desktop defaults

#navbar {
    background: rgba(18,18,18, 0.95);
    margin: 0;
    padding: 0;
    list-style-type: none;
    justify-content: flex-end;
    display: flex;
    grid-area: header;
    width: 100vw;
    transition: height 0.05s ease-out;
    position: relative;
    z-index: 10;
}

#navbar-ul-wrapper {
    margin: auto 0;
    grid-area: header;
    max-width: 1920px;

    ul {
        margin: auto 0;
        padding: 0 2vw;

        li {
            text-transform: uppercase;
            font-family: $font-secondary;
            font-weight: 300;
            display: inline;
            padding: 0 1vw;
            color: white;
        }

    }

}

@media screen and (min-width: 1920px){
    #navbar-ul-wrapper {
        margin-right: calc((100vw - 1920px) / 2);
    }
}

#navbar-item-wrapper {
    display: block;
    height: inherit;
}

.navbar-item {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    position: relative;
    font-size: 2.25vh;
    cursor: pointer;

    &:after { 
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: white;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }
    
    &:hover:after { 
        width: 100%; 
        left: 0; 
    }
}

#navbar-menu-wrapper {
    display: none;
    height: 0;
}

#navbar-menu {
    display: none;
}

.hamburger-react {
    display: none;
}

// ------------------------------------------
// Navbar mobile

@media screen and (max-width: 430px){

    #navbar {
        z-index: 2;
        height: minmax(48px, 6vh);

        &.mobile-open {
            height: 100vh;
        }
    }

    #navbar-ul-wrapper {
        margin: 0 0 auto 0;
    }

    #navbar-item-wrapper {
        position: absolute;
        left: 20vw;
        top: 20vh;
        height: 50vh;
        width: 50vw;
    }

    .topnav li a {
        display: none;
    }

    .topnav.responsive li a {
        float: none;
        display: block;
        text-align: left;
        font-size: 11vw;
        padding: 4vh 0;
    }

    #navbar-menu-wrapper {
        display: block;
        height: auto;
    }

    #navbar-menu {
        padding: 0 5vw;
        color: white;
        display: inline-block;
        font-size: 6vh;
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
    }

    .hamburger-react {
        display: block;
        padding: 0 3vw 0 0;
    }

}