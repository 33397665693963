// BASE SCSS
// not section specific

// ------------------------------------------
// Desktop defaults
html {
    display: flex;
}

body {
    margin: 0;
    // background-image: url('../assets/bg.jpg');
    background-size: auto;
    background-color: #121212;
    backdrop-filter: blur(100px);
    overflow-x: hidden;
    height: 100%;
}

#root {
    margin: auto;
    > div {
        display: grid;
        grid-template-rows: minmax(48px, 6vh) minmax(94vh, auto);
        grid-template-areas: 
        "header"
        "body";
    }

    canvas {
        height: 100% !important;
        width: 100%;
    }
}

div .content {
    grid-area: body;
    transition: opacity 0.25s;
    max-width: 1920px;
    margin: 0 auto;
    opacity: 100%;
    z-index: 1;
    height: 100%;
}

.hidden {
    opacity: 0;
    z-index: 0;
}

.hidden-display {
    display: none;
}

.subtitle {
    font-family: $font-primary;
    color: white;
    font-style: italic;
}

.main-title {
    font-family: $font-secondary;
    font-size: 90px;
    color: white;
    margin: auto;
}
